import React from 'react';
import type { PageProps } from 'gatsby';

import { CampaignProvider } from '../CampaignProvider';
import { Index } from '../pages';

interface PageContext {
  campaignId: string;
  vehicleFeedTag: string;
  language: string;
}

export default function CampaignPage({ pageContext }: PageProps<UnsafeAny, PageContext>) {
  return (
    <CampaignProvider campaign={pageContext.campaignId} vehicleFeedFilter={pageContext.vehicleFeedTag}>
      <Index />
    </CampaignProvider>
  );
}
